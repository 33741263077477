import React, { useEffect } from 'react';

const Login = () => {
    return (
        <div className="core">
            <a className='btnTwitch heroes' href='/login?twitch=1'>Conectarse con Twitch</a>
        </div>
    )
}

export default Login;