import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import overv3 from './images/overv3.png';
import overv3Hover from './images/overv3Hover.png';

export default function Draggable({left, top, isDropped}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'draggable',
  });
  const style = {
    transform: `translate3d(${transform ? transform.x+left : left}px, ${transform ? transform.y+top : top}px, 0)`,
    transition: 'transform 0.2s ease-out',
    maxWidth: 'fit-content',
    touchAction: 'none'
  }


  return (
      <img
        id='overImg'
        className='over'
        alt='logo draggable'
        src={isDropped ? overv3Hover : overv3}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      />
  );
}
