import React from 'react'
import overv3Hover from './images/overv3Hover.png';
import instagramLogo from './images/instagramLogoSquare.png';
import youtubeLogo from './images/youtubeLogoSquare.png';

const PopUp = ({ setIsDropped, setPosition, overInfo }) => {

    function closePopUp() {
        setIsDropped(false);
        setPosition({
            left: 0,
            top: 0
        });
        setTimeout(() => {
            document.getElementById('overImg').classList.add('over');
          }, 1000);
    }
    if (overInfo) {
        return (
            <div className='PopUp heroes'>
                <span className='PopUpClose' onClick={() => closePopUp()}>X</span>
                <div className='PopUpTitle'>
                    <img src={overv3Hover}></img>
                    <h3>{overInfo.title}</h3>
                </div>
                <div className='PopUpDescription'>
                    {overInfo.description}
                </div>
                {overInfo.instagramVideo || overInfo.youtubeVideo ? (
                    <div className='PopUpVideo'>
                        {overInfo.instagramVideo && (
                            <div>
                                <a href={overInfo.instagramVideo} target="_blank" rel="noopener noreferrer">
                                    <img src={instagramLogo} alt="Instagram Logo"></img>
                                </a>
                            </div>
                        )}
                        {overInfo.youtubeVideo && (
                            <div>
                                <a href={overInfo.youtubeVideo} target="_blank" rel="noopener noreferrer">
                                    <img src={youtubeLogo} alt="YouTube Logo"></img>
                                </a>
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        )
    } else {
        return '';
    }
}
export default PopUp;

