import React from 'react';

const LinkPage = () => {

  return (
    <div className="topTickets commandsBox page">
      <h2 className='heroes'>Felicidades has linkeado correctamente Twitch con Discord</h2>
    </div>
  )
}

export default LinkPage