import React from 'react';
import commandsMenu from './../images/commandsMenu.png';

const ComandBox = ({social}) => {
    const [commands, setCommands] = React.useState([]);
    React.useEffect(()=> {
        getCommands();
      },[social])
    
    const getCommands = async () => {
        let commandsList = await fetch('/api/commands?social=' + social,{ method:'POST'});
        let commandsListDef = await commandsList.json()
        setCommands(commandsListDef);
       };

       return (
        <div className="infoBox">
            {commands.map(command => (
                <div>
                    <img src={(command.image === 'default' ? commandsMenu : command.image)}></img>
                    <div className='heroes'>
                        <h3 className='name'>{command.name}</h3>
                        <div className='description'>{command.description}</div>
                        <div className='commandRow'>
                            <div className='commandRow'>
                                <div>Comando: {command.command}</div>
                                <div>Ejemplo: {command.example}</div>
                            </div>
                            <div className='commandRow'>
                                <div>Precio: {command.price}</div>
                                <div>Enfriamiento: {command.cooldown}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
       )
}
export default ComandBox