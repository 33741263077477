import React from 'react';








const Salondefama = () =>{ 
  
  const [participacion, setParticipacion] = React.useState([])

  React.useEffect(()=> {
    participantes()
  },[])

  const participantes = async () => {
   const partdata = await fetch('/api/ganadores',{ method:'POST'})
   const participaciones = await partdata.json()
   setParticipacion(participaciones)
  }
  
  
  return(

  <div className="facciones">
    <table className="heroes">
      <tr className="trtitulo"><td>Nombre de Ganadores</td><td>Veces Ganado</td></tr>
      { participacion.map(item =>(
      <tr key="item._id"><td>{item.displayname}</td><td>{item.ganador}</td></tr>  
      ))}   
    </table>
  </div>
)}

export default Salondefama;