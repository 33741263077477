import React from 'react';
import { useNavigate } from 'react-router-dom';

const LoginOnProgress = ({connecting, loginFunc}) => {
    const navigate = useNavigate();
    React.useEffect(()=> {
      if (connecting) {
        const hash = window.location.hash;
        if (hash) {
            const params = new URLSearchParams(hash.substring(1));
            const accessToken = params.get('access_token');
            if (accessToken) {
                fetch('/login?accessToken=' + accessToken, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }).then(response => response.json())
                  .then(data => {
                    loginFunc();
                    navigate('/myAccount');
                  });
            }
        }
      }
    }, [connecting, loginFunc]);
    return (
        <div className="core">
            <div className='heroes main'><h1>Cargando...</h1></div>
            <div className='preSpinner'><div class='spinner'></div></div>
        </div>
    )
}

export default LoginOnProgress;