import React from 'react';








const Participaciones = () => {

  const [participacion, setParticipacion] = React.useState([])

  React.useEffect(()=> {
    participantes()
    
  },[])

  const participantes = async () => {
   const partdata = await fetch('/api/participantes',{ method:'POST'})
   const participaciones = await partdata.json()
   setParticipacion(participaciones)
  }

  

  return (
    
  <div className="facciones">
    <table id="participantes" className="heroes">
      <tr className="trtitulo"><td>Nombre de Participantes</td><td>Tickets Acumulados</td></tr>
      { participacion.map(item =>(
      <tr key="item._id"><td>{item.displayname}</td><td>{item.ptsorteo}</td></tr>  
      ))}
   
    </table>
  </div>
  )


  


}

export default Participaciones;