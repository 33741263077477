import React from 'react';
import { Link } from 'react-router-dom'
import foralliaLogo from './images/foralliaLogo.png';
import Draggable from './Draggable';
const Header = ({left, top, isDropped}) => {
    
    return(
    <div className="header noselect">
        <Draggable left={left} top={top} isDropped={isDropped}/>
        <Link to="/"><img src={foralliaLogo} alt="Forallia Logo"/></Link>
    </div>
)} 


export default Header