import React from 'react';








const reglas = () => (

    <div className="reglas">
        <iframe src="https://www.youtube.com/embed/6FUWtypShFw" title="Sorteo de 10€" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
)
export default reglas;