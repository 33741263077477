import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import gamesImage from './images/Games.png';
import infoImage from './images/info.png';
import topImage from './images/top112.png';

const MenuExtender = () => {
    useEffect(()=> {
        document.getElementsByClassName('infoExtender')[0].addEventListener('click', () => {
            document.getElementById('infoExtendMenu').classList.add('show');
        })

        document.addEventListener('click', function(e) {
            if (e.target.id != 'infoExtendMenu' && !e.target.classList.contains('infoExtender') && !e.target.closest('.infoExtender')) {
                document.getElementById('infoExtendMenu').classList.remove('show');
            }
        })
    },[]);
    return(
        <div id="infoExtendMenu" className="noselect">
            <Link id="son" to="/top"><img src={topImage} alt="top"/><span className="responsive">Top</span></Link>
            <Link id="son" to="/games"><img src={gamesImage} alt="games"/><span className="responsive">Games</span></Link>
            <Link id="son" to="/info"><img src={infoImage} alt="info"/><span className="responsive">Info</span></Link>
        </div>
    )
}
export default MenuExtender;