import React from 'react';

const FormComp = ({formFields, apiPath}) => {
 
  const [formData, setFormData] = React.useState([]);

  React.useEffect(() => {
    handleFormFields();
  }, [formFields]);

  const [error, setError] = React.useState(false);
  
  function handleFormFields() {
    let newFormData = {};
    // Construir el nuevo objeto formData
    Object.values(formFields).forEach((value) => {
      newFormData[value[0]] = '';
    });
  
    // Establecer el nuevo formData
    setFormData(newFormData);
  }
  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    setFormData({
      ...formData,
      [name]: name === 'image' ? files[0] : value,
    });
  };

  const handleSubmitCreate = async (e) => {
    e.preventDefault();
    if (!Array.isArray(formData)) {
      for (let key in formData) {
        if ((!(key in formData) || formData[key] === '') &&
            key != 'instagramVideo' && key != 'youtubeVideo') {
          setError(key);
          return;
        }
      }
      setError(false);
  
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }

      const requestOptions = {
        method: 'POST',
        body: data
      };
      let newCommand = (await fetch('/api/adminLogin' + '?action=' + e.target.dataset.action + '&type=' + apiPath, requestOptions));
      if (newCommand) {
        handleFormFields();
      }
    }
  }
  return (
    <div className='formOver'>
      {formFields.map(([name, field]) => (
        <input
          key={name}
          className={field.className}
          placeholder={field.placeholder}
          type={field.type}
          name={name}
          onChange={handleInputChange}
          accept={field.type === "file" ? "image/*" : undefined} // Solo permitir imágenes si es un campo de tipo "file"
        >
        </input>
      ))}
      {formFields.length > 0 && (
        <div className='navTops heroes unselectable'>
          <div data-action="create" onClick={handleSubmitCreate}>Create</div>
        </div>
      )}
      {error && <p>El campo {error} está vacío.</p>}
    </div>
  )
}

export default FormComp