import React from 'react'
import { Link } from 'react-router-dom'
import Droppable from './Droppable';

class UserSearch extends React.Component{
    constructor(props) {
        super(props);
        this.state = {players: []};
      }
      componentDidMount() {
        var that = this;
        document.getElementById('userSearch').addEventListener("input", function(e){
            if(e.target.value.length >= 4){
                fetch('/api/usersearch/'+e.target.value,{ method:'POST'})
                .then(response => response.json())
                .then((data) => {
                    that.setState({players: data});
                })
            }else{
                that.setState({players: []});
            }
        })
    }
    render(){
                return(
                    <div className="core">
                        <div className='flex heroes'>
                            <h3>Busca un usuario</h3>
                            <Droppable id="droppable" className="droppableUserSearch">
                                <input id="userSearch" className="userSearch" placeholder="Nombre de usuario" type="text"></input>
                            </Droppable>
                        </div>
                        <div id="users" className="userResults">
                            {this.state.players.map(player =>
                                <div className={player.faccion}>
                                    <Link to={"/user/" + player.displayname}>
                                        <div className="previewAvatar"><img src="https://i.ibb.co/SwJjK1T/16686.png"></img></div>
                                        <div className="previewData">
                                            <div className="previewDisplayname">{player.displayname}</div>
                                            <div className="previewExtra">
                                                <div>Lv.</div>
                                                <div>{player.faccion}</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div> 
                            )}
                        </div>
                    </div>
                
                
                )
    }

}

        

export default UserSearch;

