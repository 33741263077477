import React from 'react';

const BienvenidaComp = () => {
    return (
        <div className='bienvenidaComp corruptos heroes fullCenter'>
            <h1>¡Bienvenido a Forallia corrupto!</h1>
        </div>
    )
}

export default BienvenidaComp;