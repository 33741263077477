import React from 'react';
import twitchLogo from './images/twitchLogo.png';
import tiktokLogo from './images/tiktokLogo.png';
import youtubeLogo from './images/youtubeLogo.png';
import instagramLogo from './images/instagramLogo.png';
import twitterLogo from './images/twitterLogo.png';
import costvLogo from './images/costvLogo.png';
import discordLogo from './images/discordLogo.png';
import facebookLogo from './images/facebookLogo.png';

const Redes = () => (

    <div className='page redes'>
        <div className='card zoom'>
            <a href='https://www.twitch.tv/naruxforall'><img src={twitchLogo}></img></a>
        </div>
        <div className='card zoom'>
            <a href='https://www.tiktok.com/@naruxforall?lang=es'><img src={tiktokLogo}></img></a>
        </div>
        <div className='card zoom'>
            <a href='https://www.youtube.com/@naruxforall'><img src={youtubeLogo}></img></a>
        </div>
        <div className='card zoom'>
            <a href='https://discord.gg/rQAkM3q'><img src={discordLogo}></img></a>
        </div>
        <div className='card zoom'>
            <a href='https://www.instagram.com/naruxforall/?hl=es'><img src={instagramLogo}></img></a>
        </div>
        <div className='card zoom'>
            <a href='https://twitter.com/AllNarux'><img src={twitterLogo}></img></a>
        </div>
        <div className='card zoom'>
            <a href='https://cos.tv/channel/32540282384197632'><img src={costvLogo}></img></a>
        </div>
        <div className='card zoom'>
            <a href='https://www.facebook.com/profile.php?id=100090619517444'><img src={facebookLogo}></img></a>
        </div>
    </div>

)

export default Redes