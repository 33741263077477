import React, { useState, useRef, useEffect } from 'react';
import io from "socket.io-client";

  
const StreamTimeCounter = () => {
  
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);
    const timerRef = useRef(null);

    // The state for our timer
    const [timer, setTimer] = useState('00:00:00');
    const [status, setStatus] = React.useState(false);

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }
    
    const startTimer = (e) => {
        let { total, hours, minutes, seconds } 
                    = getTimeRemaining(e);
        if (total >= 0) {
  
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }
  
  
    const clearTimer = (e) => {
  
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        // setTimer('03:00:00');
  
        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
  
    const getDeadTime = () => {
        let deadline = new Date();
  
        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 10800);
        return deadline;
    }
  
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
  
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);
  
    useEffect(()=> {
        const socket = io('https://forallia.com');
        socket.on('status', (arg) => {
            if (arg == 'ON') {
                setStatus(true);
                clearTimer(getDeadTime());
            } else if (arg == 'OFF') {
                setStatus(false);
            }
          });
        socket.on('extraTime', (arg) => {
            let actualTime = timerRef.current.innerHTML;
            actualTime = actualTime.split(':');
            let deadline = new Date();
            actualTime = ((+actualTime[0]) * 60 * 60) + (+actualTime[1]) * 60 + (+actualTime[2])
            actualTime += (arg); 
            deadline.setSeconds(deadline.getSeconds() + actualTime + 1);
            clearTimer(deadline);
        })
    },[]);

    return (
            <div className="countdown heroes">
                {status ? (
                    <>
                        <h2>Tiempo restante de Stream <span ref={timerRef}>{timer}</span></h2>
                    </>
                ) : (
                    <>
                        <h2>Stream offline</h2>
                    </>
                )}

            </div>
    )
}
  
export default StreamTimeCounter;