import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export default function Droppable({ id, children , className}) {
  const {isOver, setNodeRef} = useDroppable({
    id,
  });
  
  return (
      <span ref={setNodeRef} className={className}>
        {children}
      </span>
  );
}
