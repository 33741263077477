import React from 'react';
import TwitchLink from './components/twitchLink';

const Games = () => {

  const [game, setGames] = React.useState([]);
  const [gamer, setGamers] = React.useState([])
  let topPosition = 0;
  let actualGame = '';
  React.useEffect(()=> {
    getGames();
  },[])

  const getGames = async () => {
    let gamesList = await fetch('/api/games',{ method:'POST'});
    let gamesListDef = await gamesList.json()
    setGames(gamesListDef);
   };


  // const [totalTickets, setTotalTickets] = React.useState([])

  // let hallFamaPosition = 0;

   const changeTabGame = async (game, season = false) => {
    actualGame = game;
    let gamersList = await fetch('/api/games' + '?game=' + game + (season ? '&season=' + season : ''),{ method:'POST'});
    let gamersListDef = await gamersList.json();
    setGamers(gamersListDef);

    Array.from(document.getElementsByClassName('active')).forEach(element => {
      element.classList.remove('active');
      if (element.parentElement.classList.contains('selector')) {
        element.classList.add('hidden');
      }
    });
    document.getElementById(game).classList.add('active');
    document.getElementById(game + '-season').classList.add('active');
    document.getElementById(game + '-season').classList.remove('hidden');
    document.getElementById(game + '-season').firstElementChild.firstElementChild.value = season;
   }

   const clickSelect = (game) => {
    document.getElementById(game + '-season').firstElementChild.firstElementChild.focus();
   }

  return (
    
  <div className="topTickets page">
    <div className='navTops heroes unselectable'>
      {game.map(game => (
        <div id={game.name} className={(game.name === actualGame ? 'active' : '')} onClick={() => changeTabGame(game.name, 'All')}>{game.name.replace('_', ' ')}</div>
      )
      )}
    </div>
    <div className='navTops heroes selector'>
      {game.map(game => (
        <div className={(game.name === actualGame ? 'active' : '') + ' hidden'} id={game.name + '-season'} onClick={() => {
          clickSelect(game.name);
        }}>
          <label className='unselectable'>
            Temporada:
              <select className='heroes' onChange={(e => {
                changeTabGame(game.name, e.target.value);
              })} name={game.name + '-season'} defaultValue='All'>
                <option key='All' value='All'>Todas</option>
                {Array.from({ length: game.season}, (_, i) => i + 1).map(seasonNumber => (
                  <option key={seasonNumber} value={seasonNumber}>{seasonNumber}</option>
                ))}
              </select>
          </label>
        </div>
      )
      )} 
    </div>
    <div className='navTops'>
      <table id="gamers" className="heroes">
        <tr className="trtitulo">
          <td>#</td>
          <td>Nombre de Participantes</td>
          <td>Victorias</td>
        </tr>
        {gamer.map(user =>(
          <tr key="item._id">
            <td>{topPosition += 1}</td>
            <td>
              <div>
                <span>{user.displayname}</span>
              </div>
              <TwitchLink displayname={user.displayname}/>
            </td>
            <td>{user.totalPoints}</td>
          </tr>  
        ))}
      </table>
    </div>
  </div>
  )
}

export default Games;