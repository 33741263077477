import React from 'react';
import SorteoComp from './components/sorteoComp'
import BienvenidaComp from './components/BienvenidaComp';
import MiniTopComp from './components/miniTopComp';
import ticketSorteo from './images/ticketSorteo.png';
import top112 from './images/top112.png';
import Droppable from './Droppable';

const Main = () => {
    const [data, setData] = React.useState({});
    React.useEffect(()=> {
        const fetchData = async () => {
            await getData('/api/topTickets?limit=3&type=topHallFama', 'topHallFama');
            await getData('/api/topTickets?limit=3&type=topActual', 'topActual');
            await getData('/api/games?onProgress=1', 'topLeagues');
        };
    
        fetchData();
      }, []);

    const getData = async (url, key) => {
        let dataList = await fetch(url,{ method:'POST'});
        const result = await dataList.json();
        setData(prevState => ({
            ...prevState,
            [key]: result
        }));
    }
    return (
        <div className="home core">
            <Droppable id='welcome'><BienvenidaComp /></Droppable>
            <SorteoComp text='Sorteo de 20€ cada 2 semanas!' img={ticketSorteo}/>
            {Object.keys(data).length > 0 ? (
                <div className='infoBoxHome heroes'>
                    <MiniTopComp topData={data['topHallFama'] && data['topHallFama'].length > 0 ? data['topHallFama'] : []} title='Top tickets de todos los tiempos' img={ticketSorteo} pointsName='maxPtSorteo'/>
                    <MiniTopComp topData={data['topActual'] && data['topActual'].length > 0 ? data['topActual'] : []} title='Top tickets del sorteo ACTUAL' img={ticketSorteo} pointsName='ptsorteo'/>
                </div>
            ) : <div className='preSpinner'><div class='spinner'></div></div>}
            <SorteoComp text='Ligas con premios de tus juegos favoritos!' img={top112}/>
            {data['topLeagues'] && Object.keys(data).length > 0 ? (
                <div className='infoBoxHome heroes'>
                    {data['topLeagues'].map((top, index) => (
                        <MiniTopComp topData={top['users'] && top['users'].length > 0 ? top['users'] : []} title={'Top de la liga de ' + top['name'].replace(/_/g, ' ')} img={top112} pointsName='totalPoints'/>
                    ))}
                </div>
            ) : <div className='preSpinner'><div class='spinner'></div></div>}
        </div>
    )
}

export default Main