import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import basicRed from './images/basicred.gif';
import basicGreen from './images/basicgreen.gif';
import ticketSorteo from './images/ticketSorteo.png';
import gragea from './images/gragea.png';
import biruxPlata from './images/biruxPlata112.png';
import biruxOro from './images/biruxOro112.png';
import twitchLogo from './images/twitchLogoSquare.png';
import LoginOnProgress from './loginOnProgress';
import TwitchLink from './components/twitchLink';
import UserMenu from './components/userMenu';
const User = ({ userPlayer, isAuthenticated }) => {

    const [user, setUser] = React.useState([]);
    const [twitchActivity, setTwitchActivity] = React.useState('0 Segundos.');
    const [loading, setLoading] = React.useState(true);
    function isSub(subStatus) {
        if (!subStatus) {
            return "No se ha subscrito nunca.";
        } else {
            return "Activo";
        }
    }

    let { username } = useParams();
    useEffect(() => {
        if (userPlayer) {
            username = userPlayer.displayname;
        }
        if (username) {
            fetch('/api/user/' + username, { method: 'POST' })
            .then(response => response.json())
            .then(data => {
                setUser(data);
                setTwitchActivity(setTime(data.activityTwitchTime));
                setLoading(false);
            })
        }
    }, [userPlayer]);

    function setTime(seconds) {
        let string = 'Tiempo de actividad: ';
        if (seconds) {
            if (seconds <= 60) {
                string += seconds + ' Seg';
            } else
                if (seconds > 60 && seconds <= 3600) {
                    string += (seconds / 60).toFixed(2) + ' Min';
                } else
                    if (seconds > 3600 && seconds <= 86400) {
                        string += (seconds / 60 / 60).toFixed(2) + ' H';
                    } else
                        if (seconds > 86400 && seconds <= 2419200) {
                            string += (seconds / 60 / 60 / 24).toFixed(2) + ' D';
                        } else
                            if (seconds > 2419200 && seconds <= 29030400) {
                                string += (seconds / 60 / 60 / 24 / 28).toFixed(2) + ' M';
                            }
        } else {
            string = 'No hay tiempo de actividad registrado.'
        }
        return string;
    }

    if (loading) {
        return (<LoginOnProgress />)
    } else if (user.displayname == undefined ||
                user.hp == undefined ||
                user.hp_max == undefined ||
                user.ptsorteo == undefined ||
                user.sub == undefined) {
                return (
                    <div className="core">
                        <div className="playerName">El personaje no existe o está desactualizado.</div>
                        <div className="playerName">(Escribe en el chat o conectate durante el stream para crear o actualizar tu personaje).</div>
                    </div>
                )
    } else if (user.faccion == undefined) {
        return (
            <div className="core">
                <div className="playerName">El personaje no está en ninguna facción.</div>
                <div className="playerName">(Canjea la facción en Twitch).</div>
            </div>
        )
    } else {
        let imageLink = "";
        if (user.faccion === 'heroe') {
            imageLink = basicGreen;
        }
        if (user.faccion === 'villano') {
            imageLink = basicRed;
        }
        return (

            <div className="core">
                {isAuthenticated ? <UserMenu/> : ''}
                <div className="playerName"><span className={user.faccion + "String"}>{user.faccion}</span> {user.displayname}</div>
                <div className='social-links'>
                    <TwitchLink displayname={user.displayname}/>
                </div>
                <progress id="health" value={user.hp} max="100" data-label={"HP " + user.hp + " / " + user.hp_max}></progress>
                <div className="main">
                    <div className="character"><img src={imageLink} alt="Imagen Personaje"></img></div>
                    <div className="infoTwitch flexColumn">
                        <span className='fH'><img src={ticketSorteo}></img> Tickets Sorteo: {user.ptsorteo}</span>
                        <span className='fH'><img src={gragea}></img>Plus Ultra: {isSub(user.sub)}</span>
                        <span className='fH'><img src={biruxPlata}></img>Birux Plata: {(user.biruxPlata ? user.biruxPlata : 0)}</span>
                        <span className='fH'><img src={biruxOro}></img>Birux Oro: {(user.biruxOro ? user.biruxOro : 0)}</span>
                        <span className='fH'><img src={twitchLogo}></img>{twitchActivity}</span>
                    </div>
                </div>
            </div>


        )
    }
}
export default User;

