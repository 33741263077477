import React from 'react';

const AdminLogin = ({setUser}) => {

  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [token, setToken] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userName === '' || password === '' || token === '') {
      setError(true);
      return;
    }
    setError(false);
    let logged = (await fetch('/api/adminLogin' + '?userName=' + userName + '&password=' + password + '&token=' + token,{ method:'POST'}));
    logged = await logged.json();
    if (logged === true) {
      setUser([userName, logged]);
    } else {
      setError(true);
    }
  }
  
  return (
    <div className='core flex heroes'>
      <h1>Admin Login</h1>
      <form 
        className='flex'
        onSubmit={handleSubmit}>
        <div className='flex'>
          <h3>Usuario</h3>
          <input
            className='userSearch'
            type="text"
            value={userName}
            placeholder='Usuario'
            onChange={e => setUserName(e.target.value)}
          />
        </div>
        <div className='flex'>
          <h3>Contraseña</h3>
          <input
            className='userSearch'
            type='password'
            value={password}
            placeholder='Contraseña'
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className='flex'>
          <h3>Token 2FA</h3>
          <input
            className='userSearch'
            type='text'
            value={token}
            placeholder='Token 2FA'
            onChange={e => setToken(e.target.value)}
          />
        </div>
        <button className='btnForallia heroes'>Conectar</button>
      </form>
      {error && <p>La cuenta es incorrecta</p>}
    </div>
  )
}

export default AdminLogin;