import React from 'react';
import { Link } from 'react-router-dom'
import { Route} from 'react-router-dom'
import guia from './guia';
import Participaciones from './participaciones'
import Salondefama from './salondefama'




const sorteo = () => (






    <div id="sorteo">
            <Route path="/sorteo/guia" component={guia}/>
            <Route path="/sorteo/participaciones" component={Participaciones}/>
            <Route path="/sorteo/famosos" component={Salondefama}/>
        <div id="menu">
        <ul className="heroes">
            <li><h1 className="double">Sorteo</h1></li>
            <li>10€</li>
            <li>Cada 2 Semanas</li>
            <li>Tickets acumulables</li>
        </ul>
        <nav id="hide">
        <Link id="reglas" to="/sorteo/guia"><img  src="https://i.ibb.co/hKsf1y4/Book.png" alt="Reglas"/><br></br>Como Participar</Link>
        <Link id="reglas" to="#"><img  src="https://i.ibb.co/nsnhBPN/TICKET-SORTEO112.png" alt="Facciones"/><br></br>Participaciones</Link>
        <Link id="reglas" to="#"><img  src="https://www.iconarchive.com/download/i108363/google/noto-emoji-people-clothing-objects/12200-crown.ico" alt="Comandos"/><br></br>Salon de Fama</Link>
        </nav > 
        </div>
    </div>

)

export default sorteo